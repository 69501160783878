import Swiper, { Pagination, Navigation, Autoplay } from 'swiper';

document.addEventListener('DOMContentLoaded', (e) => {

	const blocks = document.querySelectorAll('.block--hero');

	function create_block_hero() {

		return {
			block: null,
			interval: 3000,
			isFocused: true,
			currentText: '',
			currentIndex: 0,
			items: [],
			activeItemIndex: 0,

			init: function (block) {
				this.block = block;
				this.items = this.block.querySelectorAll('.typing-text');

				if (this.items.length > 1) {

					if (this.block.dataset.style === 'slider') {
						this.slider();
					}
	
					if (this.block.dataset.style === 'typing') {
						window.addEventListener('focus', () => this.onFocus());
						window.addEventListener('blur', () => this.onBlur());
	
						this.typing();
					}
				}

				if ( this.block.classList.contains('hero-video')) { 
					this.videoHandlers();
					this.ctaHandlers();
				}

			},

			typing: function() {
				if (this.items.length) {
					if (this.currentText === '') {
						let active = this.items[this.activeItemIndex];
						active.classList.add('active');
						this.currentText = active.dataset.text;
						this.currentIndex = 0;
					}
	
					this.typeNextChar();
				}
			},

			typeNextChar: function() {
				if (this.currentIndex < this.currentText.length) {
					let active = this.items[this.activeItemIndex];
					active.innerHTML += this.currentText.charAt(this.currentIndex);
					this.currentIndex++;
					
					if (this.isFocused) {
						setTimeout(() => this.typeNextChar(), 50);
					}
				} else {
					this.finishTyping();
				}
			},

			finishTyping: function() {
				setTimeout(() => {
					if (this.isFocused) {
						let active = this.items[this.activeItemIndex];
						active.classList.remove('active');
						
						this.activeItemIndex = (this.activeItemIndex + 1) % this.items.length;
						let next = this.items[this.activeItemIndex];
						next.innerHTML = '<wbr>';
						next.classList.add('active');
						
						this.currentText = '';
						this.typing();
					}
				}, this.interval);
			},

			onFocus: function() {
				if (!document.hidden) {
					this.isFocused = true;
					this.interval = 3000;
					if (this.currentIndex < this.currentText.length) {
						this.typeNextChar();
					} else {
						this.typing();
					}
				}
			},
	
			onBlur: function() {
				this.isFocused = false;
				this.interval = 1000;
			},

			open: function() {
				const expandableDiv = this.block.querySelector('.video');
				const isExpanded = this.block.classList.contains('expanded');

				const windowLeft = window.innerWidth * 0.2;
				const windowTop = window.innerHeight * .21875;

				if (!isExpanded) {

					if ( window.matchMedia('(min-width: 992px)').matches ) {
						let rect = expandableDiv.getBoundingClientRect();
						expandableDiv.style.transform = `translate(${windowLeft - rect.left}px, ${windowTop - rect.top}px)`;
					}
					
					this.load();

					document.body.style.overflow = 'hidden';

				} else {
					document.body.style.overflow = 'auto';
					expandableDiv.style.transform = 'translate(0, 0)';
					this.unload();
				}

				this.block.classList.toggle('expanded');

			},
			
			load: function() {
				let self = this;
				let player = this.block.querySelector('.video');
				let src = player.dataset.src;
				let type = player.dataset.type

				if ( type == 'vimeo' ) {
					let id = src.split('/').pop();

					player.innerHTML = `<button class="close">Close &times;</button><iframe src="https://player.vimeo.com/video/${id}?autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
				}

				let close = player.querySelector('.close');
				close.addEventListener('click', () => {
					self.unload();
				});
			},
		
			unload: function() {
				let player = this.block.querySelector('.video');
				player.innerHTML = '';
			},

			videoHandlers: function() {
				let self = this;
				let wrapper = this.block.querySelector('.video-wrapper');
				let backdrop = this.block.querySelector('.backdrop');

				wrapper.addEventListener('click', () => {
					self.open();
				});

				backdrop.addEventListener('click', () => {
					self.open();
				})
			},

			ctaHandlers: function() {
				const ctas = this.block.querySelectorAll('.wp-block-buttons .btn.video-opener');
				ctas.forEach( cta => {
					cta.addEventListener('click', (e) => {
						e.preventDefault();
						let wrapper = this.block.querySelector('.video-wrapper');
						wrapper.click();
					});
				});
			},

			slider: function() {
				const target = this.block.querySelector('.texts');

				const wrapper = document.createElement('div');
				wrapper.classList.add('swiper');

				target.parentNode.insertBefore(wrapper, target);
				wrapper.appendChild(target);

				this.items.forEach( slide => {
					slide.classList.add('swiper-slide');
				});

				target.classList.add('swiper-wrapper');

				this.swiper();
				
			},

			swiper: function() {
				
				const swiper = new Swiper('#' + this.block.id + ' .swiper', {
					slidesPerView: 1,
					centeredSlides: true,
					spaceBetween: 64,
					loop: true,
					autoplay: {
						delay: 3000,
						disableOnInteraction: false,
						reverseDirection: true
					},
					loopAddBlankSlides: true,
					speed: 500,
					modules: [Autoplay],
				});
			},
			
			log: (message) => {
				console.log(message);
			}
		}
	}

	blocks.forEach(block => {
		const block_hero = create_block_hero();
		block_hero.init(block);
	});
});